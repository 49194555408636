<template>
  <div>
    <a-result
      status="403"
      title="403"
      sub-title="Sorry, you are not authorized to access this page."
    >
      <template #extra>
        <router-link to="/">
          <a-button type="primary"> Back to Dashboard </a-button>
        </router-link>
      </template>
    </a-result>
  </div>
</template>

<script>
export default {
};
</script>

<style lang="less" scoped>
</style>